<template>
  <div class="create-article">
    <article-form />
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import ArticleForm from "../../../components/forms/ArticleForm";
  export default {
    name: "index",
    components: { ArticleForm },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: " ئەسەر باشقۇرۇش", route: "list" },
        { title: "يېڭىدىن قوشۇش" }
      ]);
    }
  }
</script>
