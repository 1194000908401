<template>
  <div class="protocol-modal">
    <b-modal v-model="modalState" @hidden="hiddenModal" @ok="onClickOk" centered no-close-on-backdrop ok-only ok-title="قوشۇلىمەن" title="كېلىشىمنامىنى ئوقۇپ چىقىڭ">
      <protocol-content />
    </b-modal>
  </div>
</template>

<script>
  import ProtocolContent from "./ProtocolContent";
  export default {
    name: "ProtocolModal",
    components: { ProtocolContent },
    props: {
      state: Boolean
    },
    computed: {
      modalState: {
        get(){
          return this.state;
        },
        set(v){
          return v;
        }
      }
    },
    methods: {
      hiddenModal(event){
        this.$emit('close');
      },
      onClickOk(e){
        this.$emit('confirm');
      },
    }
  }
</script>

<style scoped>

</style>
