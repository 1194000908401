export default {
  methods: {
    validateState(name, parent = null, root = 'formData') {
      if ( parent ){
        const { $dirty, $error } = this.$v[root][parent][name];
        return $dirty ? !$error : null;
      }
      if ( root ){
        const { $dirty, $error } = this.$v[root][name];
        return $dirty ? !$error : null;
      }else {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }

    }
  }
}
